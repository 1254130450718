import React from 'react'
import { graphql, Link } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

import LayoutPage from 'components/layout/LayoutPage'
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'
// import { HelmetProvider } from 'react-helmet-async'

const StyledLink = styled(Link)`
  & span {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: 700;
    margin-right: 10px;
  }
`

const Category = styled.div`
  height: 28px;
  padding: 0 14px;
  bottom: 20px;
  left: 30px;
  display: flex;
  align-items: center;
  gap: 6px;

  & span {
    font-size: 12px;
    color: white;
    font-weight: 700;
  }
`

interface PostProps {
  data: {
    page: {
      title?: string
      path?: string
      seo: any
      // eslint-disable-next-line
      flexContent: GatsbyTypes.WpPost_Flexcontent
    }
    posts: {
      nodes: {
        title: string
        uri: string
      }[]
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PostTemplate: React.FC<PostProps> = ({
  data: {
    page: { flexContent, seo, title },
    posts: { nodes: posts },
  },
  location = {},
  prefix = 'post_Flexcontent',
  pageContext,
}) => {
  if (flexContent.homeTemplate) {
    return (
      // <HelmetProvider context={{}}>
      <Layout>
        <SEO seo={seo} />
        <FlexContentHandler
          prefix={prefix}
          fields={flexContent}
          location={location}
        />
      </Layout>
      // </HelmetProvider>
    )
  }

  return (
    // <HelmetProvider context={{}}>
    <Layout>
      <SEO seo={seo} />
      <LayoutPage
        title={title || ''}
        prefix={prefix}
        flexContent={flexContent}
        location={location}
        pageContext={pageContext}
      />
      <AfterContent
        title={flexContent.flex?.find(
          (o) => o?.fieldGroupName === 'post_Flexcontent_Flex_OtherArticles'
        )}
        posts={posts}
      />
    </Layout>
    // </HelmetProvider>
  )
}

function AfterContent({ title, posts }: { title: any; posts: any }) {
  return (
    <section className="py-lg-5">
      <div className="container py-5">
        <h2 className="font-weight-extraBold font-size-larger mb-5">
          {title?.title}
        </h2>
        <div className="d-flex flex-wrap">
          {posts.map((post: any) => (
            <Link
              to={post.uri}
              key={post.uri}
              className="col-12 col-md-6 col-lg-4 px-2 mb-4"
            >
              <div className="position-relative mb-4">
                <Plaatjie
                  image={post?.flexContent?.banner?.image}
                  alt={title?.title}
                />
                {post?.categories?.nodes?.length > 0 && (
                  <Category className="position-absolute bg-black">
                    {post?.categories?.nodes[0]?.slug === 'video-case' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1602_91)">
                          <path
                            d="M14 14H0V1.75C0 1.28587 0.184374 0.840752 0.512563 0.512563C0.840752 0.184374 1.28587 0 1.75 0L12.25 0C12.7141 0 13.1592 0.184374 13.4874 0.512563C13.8156 0.840752 14 1.28587 14 1.75V14ZM1.16667 12.8333H12.8333V1.75C12.8333 1.59529 12.7719 1.44692 12.6625 1.33752C12.5531 1.22812 12.4047 1.16667 12.25 1.16667H1.75C1.59529 1.16667 1.44692 1.22812 1.33752 1.33752C1.22812 1.44692 1.16667 1.59529 1.16667 1.75V12.8333ZM4.66667 10.3384V3.66158L10.5093 7L4.66667 10.3384ZM5.83333 5.67175V8.32825L8.15733 7L5.83333 5.67175Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1602_91">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    <span>{post?.categories?.nodes[0]?.name}</span>
                  </Category>
                )}
              </div>
              <h4 className="font-size-large font-weight-extraBold mb-2">
                {post.title}
              </h4>
              <StyledLink to={post.uri} className="d-flex align-items-center">
                <span>Bekijk de case</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1602_94)">
                    <path
                      d="M10.2128 10.8215L14.8297 5.93022C14.9581 5.79415 15.0122 5.61511 14.9919 5.43608C14.9919 5.41459 14.9852 5.40027 14.9852 5.37878C14.9852 5.37162 14.9852 5.37162 14.9784 5.36446C14.9784 5.35014 14.9717 5.33581 14.9649 5.32149C14.9649 5.31433 14.9581 5.30717 14.9581 5.30001C14.9514 5.28568 14.9514 5.27136 14.9446 5.2642C14.9446 5.25704 14.9379 5.24988 14.9379 5.24988C14.9311 5.23555 14.9243 5.22839 14.9243 5.21407C14.9243 5.20691 14.9176 5.19975 14.9176 5.19975C14.9108 5.18542 14.9041 5.17826 14.8973 5.16394C14.8905 5.15678 14.8905 5.14962 14.8838 5.14246C14.877 5.13529 14.8703 5.12097 14.8635 5.11381C14.8567 5.09949 14.8432 5.09233 14.8297 5.078L14.823 5.07084L10.2128 0.179565C9.98969 -0.0567629 9.62466 -0.0567629 9.39483 0.179565C9.17176 0.415893 9.17176 0.802612 9.39483 1.0461L13.0248 4.8918L0.573294 4.8918C0.255584 4.8918 -0.00128864 5.16394 -0.00128865 5.50053C-0.00128867 5.83712 0.255583 6.10925 0.573294 6.10925L13.0316 6.10925L9.40159 9.95496C9.28667 10.0767 9.2326 10.2343 9.2326 10.3846C9.2326 10.5422 9.28667 10.6997 9.40159 10.8143C9.62466 11.0578 9.98969 11.0578 10.2128 10.8215Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1602_94">
                      <rect
                        width="11"
                        height="15"
                        fill="white"
                        transform="translate(15) rotate(90)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </StyledLink>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export const pageQuery = graphql`
  query postById($pageId: String!) {
    page: wpPost(id: { eq: $pageId }) {
      ...generalPostFragment
    }

    posts: allWpPost(limit: 3) {
      nodes {
        ...generalPostFragment
      }
    }
  }
`

export default PostTemplate
